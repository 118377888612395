import React from 'react'
import Main from './components/Main'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import PrivacyPolicy from './components/PrivacyPolicy'
import Tnc from './components/Tnc'
import PageNotFound from './PageNotFound'
import RefundPolicy from './components/RefundPolicy'
import ShippingAndDeliveryPolicy from './components/ShippingandDeliveryPolicy'

export default function App() {
  return (
    <>
    <BrowserRouter>
    <Routes>
      <Route path='/' element={<Main />}/>
      <Route path='/privacy-policy' element={<PrivacyPolicy />}/>
      <Route path='/tnc' element={<Tnc />}/>
      <Route path='/refund-policy' element={<RefundPolicy />}/>
      <Route path='/s&dpolicy' element={<ShippingAndDeliveryPolicy />}/>
      <Route
          path="/internship"
          element={<NavigateInternship />}
        />
      <Route path='/*' element={<PageNotFound />}/>
    </Routes>
    </BrowserRouter>      
    </>
  )
}

function NavigateInternship() {
  React.useEffect(() => {
    window.location.href = 'https://internship.compwallah.com';
  }, []);

  // Placeholder return to satisfy React component requirements
  return null;
}