import React, { Component } from 'react'
export default class About extends Component {
  render() {
    return (
        <div className="container-xxl py-5" id="about">
            <div className="container py-5 px-lg-5">
                <div className="row g-5 align-items-center">
                    <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                        <h5 className="text-primary-gradient fw-medium">About Us</h5>
                        <h1 className="mb-4">Providing Top-Notch Software Solutions and IT Services</h1>
                        <p className="mb-4">At CompWallah, we are committed to delivering cutting-edge software solutions and comprehensive IT services to empower businesses and organizations in their digital journey. With a focus on innovation, quality, and customer satisfaction, we strive to be your trusted technology partner.</p>
                        <div className="row g-4 mb-4">
                            <div className="col-sm-4 wow fadeIn" data-wow-delay="0.5s">
                                <div className="d-flex">
                                    <i className="fa fa-cogs fa-2x text-primary-gradient flex-shrink-0 mt-1"></i>
                                    <div className="ms-3">
                                        <h2 className="mb-0" data-toggle="counter-up">107</h2>
                                        <p className="text-primary-gradient mb-0">Active Clients</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4 wow fadeIn" data-wow-delay="0.5s">
                                <div className="d-flex">
                                    <i className="fa fa-user fa-2x text-primary-gradient flex-shrink-0 mt-1"></i>
                                    <div className="ms-3">
                                        <h2 className="mb-0" data-toggle="counter-up">12K+</h2>
                                        <p className="text-primary-gradient mb-0">Visitor Count</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4 wow fadeIn" data-wow-delay="0.7s">
                                <div className="d-flex">
                                    <i className="fa fa-comments fa-2x text-secondary-gradient flex-shrink-0 mt-1"></i>
                                    <div className="ms-3">
                                        <h2 className="mb-0" data-toggle="counter-up">73</h2>
                                        <p className="text-secondary-gradient mb-0">Clients Reviews</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a href="/" className="btn btn-primary-gradient py-sm-3 px-4 px-sm-5 rounded-pill mt-3">Lets Discuss</a>
                    </div>
                    <div className="col-lg-6">
                    <img src="img/about.svg" alt="About SVG" />
                    </div>
                </div>
            </div>
        </div>
    )
  }
}
