import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class Footer extends Component {
  render() {
    return (
    <div className="container-xxl bg-primary text-light footer wow fadeIn" data-wow-delay="0.1s">
        <div className="container py-5 px-lg-5">
            <div className="row g-5">
                <div className="col-md-6 col-lg-4">
                    <h4 className="text-white mb-4">Address</h4>
                    <p><i className="fa fa-map-marker-alt me-3"></i>Chavli Chauk, Road No 4, Pune, Maharashtra, India</p>
                    <p>
                        <a href="tel:9403027293" className='text-white'>
                            <i className="fa fa-phone-alt me-3"></i>
                            +91 9403027293
                        </a>
                    </p>
                    <p>
                        <a href="mailto:contact@compwallah.com" className='text-white'>
                            <i className="fa fa-envelope me-3"></i>
                            contact@compwallah.com
                        </a>
                    </p>
                        
                    <div className="d-flex pt-2">
                        <Link className="btn btn-outline-light btn-social" to="https://twitter.com/compwallah"><i className="fab fa-twitter"></i></Link>
                        <Link className="btn btn-outline-light btn-social" to="https://www.facebook.com/public/compwallah"><i className="fab fa-facebook-f"></i></Link>
                        <Link className="btn btn-outline-danger text-white btn-social" to="https://www.instagram.com/thecompwallah/"><i className="fab fa-instagram"></i></Link>
                        <Link className="btn btn-outline-light btn-social" to="https://www.linkedin.com/company/thecompwallah"><i className="fab fa-linkedin-in"></i></Link>
                    </div>
                </div>
                {/* <div className="col-md-6 col-lg-3">
                    <h4 className="text-white mb-4">Quick Link</h4>
                    <Link className="btn btn-link" to="/#about">About Us</Link>
                    <Link className="btn btn-link" to="/#contact">Contact Us</Link>
                    <Link className="btn btn-link" to="/privacy-policy">Privacy Policy</Link>
                    <Link className="btn btn-link" to="/tnc">Terms & Condition</Link>
                    <Link className="btn btn-link" to="/">Career</Link>
                </div> */}
                <div className="col-md-6 col-lg-4">
                    <h4 className="text-white mb-4">Popular Link</h4>
                    <Link className="btn btn-link" to="/">Blogs</Link>
                    <Link className="btn btn-link" to="https://internship.compwallah.com">Internship</Link>
                    <Link className="btn btn-link" to="/tnc">Terms & Condition</Link>
                    <Link className="btn btn-link" to="/#contact">Contact Us</Link>
                </div>
                <div className="col-md-6 col-lg-4">
                    <h4 className="text-white mb-4">Newsletter</h4>
                    <p>Subscribe now for a dose of knowledge delivered straight to your inbox.</p>
                    <div className="position-relative w-100 mt-3">
                        <input className="form-control border-0 rounded-pill w-100 ps-4 pe-5" type="text" placeholder="Your Email" style={{ height: '48px' }} />
                        <button type="button" className="btn shadow-none position-absolute top-0 end-0 mt-1 me-2"><i className="fa fa-paper-plane text-primary-gradient fs-4"></i></button>
                    </div>
                </div>
            </div>
        </div>
        <div className="container px-lg-5">
            <div className="copyright">
                <div className="row">
                    <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                        &copy; <Link className="border-bottom" to="/">CompWallah</Link>, All Right Reserved 2024                        
                    </div>
                    <div className="col-md-6 text-center text-md-end">
                        <div className="footer-menu">
                            <Link to="/tnc">Terms and Condition</Link>
                            <Link to="/refund-policy">Refund Policy</Link>
                            <Link to="/privacy-policy">Privacy Policy</Link>
                            <Link to="/s&dpolicy">Shipping & Delivery Policy</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
  }
}
