import Features from "./Services";
import About from "./About";
import Hero from "./Hero";
import Navbar from "./Navbar";
import CheckOut from "./CheckOut";
import WhyUs from "./WhyUs";
import Contact from "./Contact";
import Footer from "./Footer";

function Main() {
  return (
    <div>      
      <div data-bs-spy="scroll" data-bs-target=".navbar" data-bs-offset="51">
      <Navbar />
        <div className="container-xxl bg-white p-0">
          <Hero id="home" />
          <About id="about" />
          <Features id="services" />
          <CheckOut />
          <WhyUs id="whyus" />
          <Contact id="contact" />
          <Footer />
          <a href="/#" className="btn btn-lg btn-lg-square back-to-top pt-2"><i className="bi bi-arrow-up text-white"></i></a>
        </div>
      </div>
    </div>
  );
}

export default Main;
