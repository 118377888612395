import React, { Component } from 'react'

export default class Features extends Component {
  render() {
    return (
    <div className="container-xxl py-5" id="services">
        <div className="container py-5 px-lg-5">
            <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                <h5 className="text-primary-gradient fw-medium">Our Services</h5>
                <h1 className="mb-5">We Provide</h1>
            </div>
            <div className="row g-4">
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="feature-item bg-light rounded p-4">
                        <div className="d-inline-flex align-items-center justify-content-center bg-primary-gradient rounded-circle mb-4" style={{ width: '60px', height: '60px' }}>
                            <i className="fa fa-eye text-white fs-4"></i>
                        </div>
                        <h5 className="mb-3">Custom Software Development</h5>
                        <p className="m-0">From concept to implementation, we specialize in bringing your vision to life through tailored software solutions that cater specifically to your business requirements.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                    <div className="feature-item bg-light rounded p-4">
                        <div className="d-inline-flex align-items-center justify-content-center bg-secondary-gradient rounded-circle mb-4" style={{ width: '60px', height: '60px' }}>
                            <i className="fa fa-layer-group text-white fs-4"></i>
                        </div>
                        <h5 className="mb-3">Web Development</h5>
                        <p className="m-0">Create a powerful online presence with our expert web development services. We specialize in building responsive, user-friendly, and scalable websites and management portal.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="feature-item bg-light rounded p-4">
                        <div className="d-inline-flex align-items-center justify-content-center bg-primary-gradient rounded-circle mb-4" style={{ width: '60px', height: '60px' }}>
                            <i className="fa fa-edit text-white fs-4"></i>
                        </div>
                        <h5 className="mb-3">IT Consulting</h5>
                        <p className="m-0">Navigate the complex IT landscape with confidence. Our experienced consultants provide strategic guidance to help you make informed technology decisions.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.2s">
                    <div className="feature-item bg-light rounded p-4">
                        <div className="d-inline-flex align-items-center justify-content-center bg-secondary-gradient rounded-circle mb-4" style={{ width: '60px', height: '60px' }}>
                            <i className="fa fa-shield-alt text-white fs-4"></i>
                        </div>
                        <h5 className="mb-3">Fully Secured</h5>
                        <p className="m-0">Our team of certified cybersecurity professionals is dedicated to staying ahead of the curve, implementing advanced measures to fortify your systems against cyber threats.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                    <div className="feature-item bg-light rounded p-4">
                        <div className="d-inline-flex align-items-center justify-content-center bg-primary-gradient rounded-circle mb-4" style={{ width: '60px', height: '60px' }}>
                            <i className="fa fa-cloud text-white fs-4"></i>
                        </div>
                        <h5 className="mb-3">Cloud Solutions</h5>
                        <p className="m-0">Harness the power of the cloud to enhance scalability, flexibility, and security. Our cloud solutions are designed to optimize your business operations.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="feature-item bg-light rounded p-4">
                        <div className="d-inline-flex align-items-center justify-content-center bg-secondary-gradient rounded-circle mb-4" style={{width: '60px', height: '60px'}}>
                            <i className="fa fa-mobile-alt text-white fs-4"></i>
                        </div>
                        <h5 className="mb-3">Mobile App Development</h5>
                        <p className="m-0">Reach your audience on the go with our custom mobile app development services. We develop engaging and intuitive applications for iOS and Android platforms.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
  }
}
