import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class Hero extends Component {  
    render() {
    return (
        <div className="container-xxl bg-primary hero-header" id='home'>
            <div className="container px-lg-5">
                <div className="row g-0">
                    <div className="col-lg-6 text-center text-lg-start">
                        <h1 className="text-white mb-4 animated slideInDown">You Run Your Business<br /> We Run Your IT</h1>

                        <Link to="https://internship.compwallah.com" className="btn btn-primary-gradient py-sm-3 px-4 px-sm-5 rounded-pill me-3 animated slideInLeft">Internships</Link>
                        <a href="/#contact" className="btn btn-secondary-gradient py-sm-3 px-4 px-sm-5 rounded-pill animated slideInRight">Contact Us</a>
                    </div>
                    <div className="col-lg-6 d-flex justify-content-center justify-content-lg-end fadeInUp" data-wow-delay="0.3s">
                        {<img src="img/hero.svg" width={400} height={400} alt="Hero SVG" />}
                    </div>
                </div>
            </div>
        </div>
    )
  }
}