import React, { Component } from 'react'

export default class CheckOut extends Component {
  render() {
    return (
    <div className="container-xxl py-5">
        <div className="container py-5 px-lg-5">
            <div className="row g-5 align-items-center">
                <div className="col-lg-8 wow fadeInUp" data-wow-delay="0.1s">
                    <h5 className="text-primary-gradient fw-medium">Check this out</h5>
                    <h1 className="mb-4">What Sets Us Apart</h1>
                    <p className="mb-4">CompWallah stands out with its commitment to innovation, a customer-centric approach prioritizing client success, unwavering quality assurance through rigorous testing, and a timely delivery focus driven by agile methodologies in the fast-paced digital landscape.</p>
                    <p><i className="fa fa-check text-primary-gradient me-3"></i>Innovative Solutions</p>
                    <p><i className="fa fa-check text-primary-gradient me-3"></i>Customer-Centric Approach</p>
                    <p><i className="fa fa-check text-primary-gradient me-3"></i>Timely Delivery</p>
                    <p className="mb-4"><i className="fa fa-check text-primary-gradient me-3"></i>Quality Assurance</p>
                    <a href="/#contact" className="btn btn-primary-gradient py-sm-3 px-4 px-sm-5 rounded-pill mt-3">Contact Us</a>
                </div>
                {/* <div className="col-lg-4 d-flex justify-content-center justify-content-lg-end wow fadeInUp" data-wow-delay="0.3s">
                    <div className="owl-carousel screenshot-carousel">
                        <img className="img-fluid" src="img/screenshot-1.png" alt="" />
                        <img className="img-fluid" src="img/screenshot-2.png" alt="" />
                        <img className="img-fluid" src="img/screenshot-3.png" alt="" />
                        <img className="img-fluid" src="img/screenshot-4.png" alt="" />
                        <img className="img-fluid" src="img/screenshot-5.png" alt="" />
                    </div>
                </div> */}
            </div>
        </div>
    </div>
    )
  }
}