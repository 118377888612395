import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export default function Navbar() {
  const [scrolled, setScrolled] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 2;
      setScrolled(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 991.98);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <nav className={`navbar  navbar-light px-4 px-lg-5 py-3 py-lg-0 ${scrolled ? 'navbar-expand-lg' : 'navbar-expand-lg'}`}>
      <div className="container-xxl">
        <Link to="/" className="navbar-brand p-0">
          <div className="d-flex align-items-center">
            <img src={isMobile ? '/img/logo.svg' : (scrolled ? '/img/logo.svg' : '/img/logow.svg')} alt="Logo" className="me-1" />
            <h1 className="m-0">CompWallah</h1>
          </div>
        </Link>
  
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
          <span className="fa fa-bars"></span>
        </button>
  
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav mx-auto py-0">
            <a href="#home" className="nav-item nav-link">Home</a>
            <a href="#about" className="nav-item nav-link">About</a>
            <a href="#services" className="nav-item nav-link">Services</a>
            <a href="#whyus" className="nav-item nav-link">Why Us</a>
            <a href="#contact" className="nav-item nav-link">Contact Us</a>
          </div>
          <Link to="https://internship.compwallah.com" className="btn btn-primary-gradient rounded-pill py-2 px-4 ms-3 d-none d-lg-block">Career</Link>
        </div>
      </div>
    </nav>
  );
}
