import React, { Component } from 'react'

export default class Contact extends Component {
  render() {
    return (
    <div className="container-xxl py-5" id="contact">
        <div className="container py-5 px-lg-5">
            <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                <h5 className="text-primary-gradient fw-medium">Contact Us</h5>
                <h1 className="mb-5">Get In Touch!</h1>
            </div>
            <div className="row justify-content-center">
                <div className="col-lg-9">
                    <div className="wow fadeInUp" data-wow-delay="0.3s">
                        <p className="text-center mb-4">Reach out to CompWallah - your partner in innovation and technology. Let's collaborate to turn your ideas into reality and drive your business to new heights.</p>
                        <form action="contact.php" method="POST" className="php-email-form">
                            <div className="row g-3">
                                <div className="col-md-6">
                                    <div className="form-floating">
                                        <input type="text" className="form-control" name='name' id="name" placeholder="Your Name" />
                                        <label htmlFor="name">Your Name</label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-floating">
                                        <input type="email" className="form-control" name='email' id="email" placeholder="Your Email" />
                                        <label htmlFor="email">Your Email</label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-floating">
                                        <input type="text" className="form-control" name='subject' id="subject" placeholder="Subject" />
                                        <label htmlFor="subject">Subject</label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-floating">
                                        <textarea className="form-control" name='message' placeholder="Leave a message here" id="message" style={{ height: '150px' }}></textarea>
                                        <label htmlFor="message">Message</label>
                                    </div>
                                </div>
                                <div className="col-12 text-center">
                                    <button name='contact' className="btn btn-primary-gradient rounded-pill py-3 px-5" type="submit">Send Message</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
  }
}
