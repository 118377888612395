import React from 'react';
import { Link } from 'react-router-dom';

const ShippingAndDeliveryPolicy = () => {
  return (
    <div className="container-xxl py-5">
      <div className="container py-5 px-lg-5">
        <div className="col g-5 align-items-center">
          <Link to="/" className="btn btn-primary-gradient py-sm-3 px-4 px-sm-5 rounded-pill me-3 animated slideInLeft">Home</Link>
          <div className="row-lg-6 wow fadeInUp" data-wow-delay="0.3s" style={{textAlign: 'justify'}}>
            <h1 className="mb-4 text-center">Shipping and Delivery Policy</h1>
            <p>
              Welcome to CompWallah's shipping and delivery policy. Here, we outline our procedures and timelines for shipped items to ensure a smooth experience for our clients.
            </p>
            <h5 className="text-primary-gradient fw-medium">Order Processing and Shipping Time:</h5>
            <p>
              Upon ordering our services such as web development, database management, or any other IT services, we initiate processing immediately. Processing time typically ranges from 1 to 3 business days, depending on the service's complexity and scope. We then deliver the services promptly according to the agreed timelines.
            </p>
            <h5 className="text-primary-gradient fw-medium">Shipping Costs:</h5>
            <p>
              As our services are primarily digital and delivered online, there are generally no shipping costs involved. However, in cases of physical deliverables or specific requirements incurring shipping costs, such as software installation CDs or documentation, we communicate applicable charges upfront during order placement.
            </p>
            <h5 className="text-primary-gradient fw-medium">Tracking Orders:</h5>
            <p>
              For services involving multiple stages or milestones, we provide regular updates and progress reports to keep you informed about your order's status. Our customer support team is available to assist with any queries regarding order tracking or estimated delivery times.
            </p>
            <h5 className="text-primary-gradient fw-medium">Contact Information:</h5>
            <p>
              Should you have any questions or concerns regarding our shipping and delivery policy, please feel free to contact us via email at <a href="mailto:contact@compwallah.com">contact@compwallah.com</a>. Our dedicated customer support team is available during business hours.
            </p>
            <p>
              At CompWallah, we are committed to delivering high-quality services efficiently and ensuring a seamless experience for our clients. Thank you for choosing us for your software development and IT services needs.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShippingAndDeliveryPolicy;
