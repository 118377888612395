import React, { Component } from 'react'

export default class WhyUs extends Component {
  render() {
    return (
    <div className="container-xxl py-5" id='whyus'>
        <div className="container py-5 px-lg-5">
            <div className="text-center pb-4 wow fadeInUp" data-wow-delay="0.1s">
                <h5 className="text-primary-gradient fw-medium">Why Us</h5>
                <h1 className="mb-5">Why Choose CompWallah</h1>
            </div>
            <div className="row gy-5 gx-4 justify-content-center">
                <div className="col-lg-4 col-sm-6 text-center pt-4 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="position-relative bg-light rounded pt-5 pb-4 px-4">
                        <div className="d-inline-flex align-items-center justify-content-center bg-primary-gradient rounded-circle position-absolute top-0 start-50 translate-middle shadow" style={{ width: '100px', height: '100px' }}>
                            <i className="fa fa-cog fa-3x text-white"></i>
                        </div>
                        <h5 className="mt-4 mb-3">Innovation</h5>
                        <p className="mb-0">CompWallah thrives on innovation, delivering cutting-edge solutions that elevate your business above the ordinary and keep it ahead of the curve.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6 text-center pt-4 wow fadeInUp" data-wow-delay="0.3s">
                    <div className="position-relative bg-light rounded pt-5 pb-4 px-4">
                        <div className="d-inline-flex align-items-center justify-content-center bg-secondary-gradient rounded-circle position-absolute top-0 start-50 translate-middle shadow" style={{ width: '100px', height: '100px' }}>
                            <i className="fa fa-address-card fa-3x text-white"></i>
                        </div>
                        <h5 className="mt-4 mb-3">Client-Centric Approach</h5>
                        <p className="mb-0">Your success is our priority. We work closely with you to understand your goals, challenges, and vision, tailoring our services accordingly.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6 text-center pt-4 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="position-relative bg-light rounded pt-5 pb-4 px-4">
                        <div className="d-inline-flex align-items-center justify-content-center bg-primary-gradient rounded-circle position-absolute top-0 start-50 translate-middle shadow" style={{ width: '100px', height: '100px' }}>
                            <i className="fa fa-check fa-3x text-white"></i>
                        </div>
                        <h5 className="mt-4 mb-3">Reliability</h5>
                        <p className="mb-0">Count on us for reliable and scalable solutions. We are committed to meeting deadlines and exceeding expectations</p>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
  }
}