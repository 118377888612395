import React from 'react';
import { Link } from 'react-router-dom';

const RefundPolicy = () => {
  return (
    <div className="container-xxl py-5">
      <div className="container py-5 px-lg-5">
        <div className="col g-5 align-items-center">
          <Link to="/" className="btn btn-primary-gradient py-sm-3 px-4 px-sm-5 rounded-pill me-3 animated slideInLeft">Home</Link>
          <div className="row-lg-6 wow fadeInUp" data-wow-delay="0.3s" style={{textAlign: 'justify'}}>
            <h1 className="mb-4 text-center">Refund Policy</h1>
            <p>
              Kindly align the Refund policy to be inline with the products and services available on the website and include the number of days taken to process the refund and credit the amount to the customer's bank account (for e.g. 5-7 working days).
            </p>
            <p>
              For any further queries regarding the refund policy, please feel free to contact us using the provided contact information.
            </p>
            <h5 className="text-primary-gradient fw-medium">Contact Us:</h5>
            <ul>
              <li>Indian Contact Number: +91 9403027293</li>
              <li>Email Address: <a href="mailto:contact@compwallah.com">contact@compwallah.com</a></li>
              <li>Operating Address: Pune, Maharashtra, India</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RefundPolicy;
