import React from 'react';

export default function PageNotFound() {
  return (
    <div className="container mt-3">
        <a href="/" className="navbar-brand p-0">
            <h1 className="m-0">CompWallah</h1>
        </a>
        <div className="row">
            {/* Left column for image */}
            <div className="col-md-6">
            <img className="img-fluid" src="img/notfound.svg" alt="Page not found" />
            </div>
            {/* Right column for text and link */}
            <div className="col-md-6 d-flex align-items-center">
            <div>
                <h2>Page not found</h2>
                <p>Sorry, the page you are looking for does not exist.</p>
                <a href="/">Go to Home Page</a>
            </div>
            </div>
        </div>
    </div>
  );
}
